const styles = {
  section: 'bg-sage-100 pb-20',
  sectionContainer: 'container mx-auto px-5 pt-5 lg:pt-10 pb-5 lg:pb-10',
  h2: 'font-title text-2xl lg:text-3xl text-fiery-orange-700 mb-8',
  p: 'mb-3 text-lg',
  buttonBase: 'font-button ',
  buttonPrimary: 'font-button text-white bg-sage-500 border-0 py-2 px-8 focus:outline-none hover:bg-sage-600 rounded ',
  textArea: "w-full bg-white rounded border border-gray-300 focus:border-sage-500 focus:ring-3 focus:ring-sage-200 text-base outline-none text-feldgrau-900 py-2 px-3 transition-colors duration-200 ease-in-out",
}

export default styles;

export const styler = Object.fromEntries(
  Object.entries(styles)
  .map(([k, v]) => [k, function(){ return [v, ...arguments].join(' ')}])
)