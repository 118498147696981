// import { useSelector, useDispatch } from 'react-redux';
import { useState, useMemo, useEffect } from 'react';
// import { profileUpdated } from '../state/profileSlice';
import PageStandard from "../components/PageStandard";
import ProfileForm from '../components/ProfileForm';
import styles, {styler} from '../styles';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, useLocation, useSearchParams, useLoaderData } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getReferralSources } from '../state/apiClient';

export async function loader() {
  try {
    const { data: referralSources } = await getReferralSources()
    return { referralSources }
  } catch (error) {
    return { referralSources: null }
  }
}

// https://react-hook-form.com/get-started#Integratingwithglobalstate

export default function Join() {

  const { getSession, login } = useAuth()
  const navigate = useNavigate();

  const existingUser = useMemo(
    () => getSession(),
    // eslint-disable-next-line
    []
  )

  useEffect(
    () => {
      if(existingUser) navigate('/profile')
    },
    // eslint-disable-next-line
    [existingUser]
  )

  let { state: locationState }  = useLocation();

  const [searchParams] = useSearchParams();

  const isIframeMode = (searchParams.get('mode') === 'iframe')

  const { referralSources } = useLoaderData();

  // const isAdult = useSelector(state => state.profile.isAdult);
  // const isAdult = profile.isAdult;
  const [isAdult, setIsAdult] = useState(false)
  // const dispatch = useDispatch();

  const onAttest = (data) => {
    setIsAdult(true)
    // dispatch(profileUpdated({isAdult: true}))
  }

  const onSuccess = (response) => {
    login(response.data.token)
    navigate('/welcome' + (isIframeMode ? '?mode=iframe' : ''))
  }

  const onFailure = (error) => {
    toast.error('There was an error submitting your registration. Please try again.')
  }

  return (
    <PageStandard showHeader={!isIframeMode} showFooter={!isIframeMode}>

      <h2 className={styles.h2}>Join CannaWorkforce Connector</h2>

      <div className='mb-5'>
        <p className={styles.p}>We'd love to have you join our community so you can connect with companies and opportunities in the cannabis industry!</p>
        
        {!isAdult && (
          <>
            <p className={styles.p + ' mb-5'}>Because of legal requirements in the industry, please confirm that you are over 21 years old before proceeding:</p>

            <button className={styles.buttonPrimary} onClick={onAttest}>I am over 21 years old</button>
          </>
        )}


      </div>

      {isAdult && (
        <ProfileForm
          action='REGISTER'
          onSuccess={onSuccess}
          onFailure={onFailure}
          defaultValues={{
            isAdult,
            email: (locationState && 'email' in locationState && locationState.email.trim() !== '' ? locationState.email : null)
          }}
          referralSources={referralSources}
        />
      )}

      <p className={styler.p('mt-20 italic')}>All qualified applicants will receive consideration for employment without regard to race, color, religion, sex, national origin, sexual orientation, gender identity, disability and protected veterans status or any other characteristic protected by law.</p>

    </PageStandard>
  )
}
