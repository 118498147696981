// https://www.freecodecamp.org/news/how-to-validate-forms-in-react/

export const Label = ({ label, id, hasError, required}) => {

  const errorClasses = 'text-stiletto-700'

  return (
    <label htmlFor={id} className={'font-label font-semibold text-sm' + (hasError ? ` ${errorClasses}` : '')}>
      {label}
      {required && (<span className="inline-block relative top-[-2px] ml-2 h-[6px] w-[6px] rounded-[3px] mt-[-4px] bg-stiletto-600"></span>)}
    </label>
  )

}
