import { useFormContext } from "react-hook-form"
import { Label } from "./Label";
import { HelperText } from "./HelperText";

export const Select = ({ label, id, options, defaultOption, helperText, className, required }) => {

  const {
    register,
    formState: { errors },
  } = useFormContext()

  const message = (id in errors ? errors[id].message : helperText)
  
  return (
    <div className={className}>
      <Label label={label} id={id} hasError={id in errors} required={required} />
      <select
        id={id}
        className="w-full h-10 bg-white rounded border border-gray-300 focus:border-sage-500 focus:ring-3 focus:ring-sage-200 text-base outline-none text-feldgrau-900 py-2 px-3 transition-colors duration-200 ease-in-out"
        {...register(id)}
        defaultValue={defaultOption ? "" : null}
      > 
        {defaultOption && (<option value="" disabled hidden>{defaultOption}</option>)}
        {options.map(option => {
          const val = (Array.isArray(option) && option.length >= 2 ? option[1] : option)
          const label = (Array.isArray(option) && option.length >= 2 ? option[0] : option)
          return (<option value={val} key={val}>{label}</option>)
        })}
      </select>
      <HelperText message={message} hasError={id in errors} />
    </div>
  )

}
