import axios from 'axios';
import _ from "lodash"
import { getSessionToken } from './sessionToken';

const apiClient = axios.create({
  baseURL: '/api',
});

// https://axios-http.com/docs/interceptors
apiClient.interceptors.request.use(
  (config) => {
    // do something before requests are sent
 
    const token = getSessionToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config
  },
  (error) => {
    // do something with request errors
    return Promise.reject(error)
  }
);

export default apiClient;

export const packPayload = (payload, stripNulls = false) => {
  return _.chain(payload)
    .toPairs()
    .filter(([k, v]) => (stripNulls ? v !== null : true))
    .map(([k, v]) => [_.snakeCase(k), v])
    .fromPairs()
    .value()
  ;
}

export const unpackPayload = (payload) => {
  return _.chain(payload)
    .toPairs()
    .map(([k, v]) => [_.camelCase(k), v])
    .fromPairs()
    .value()
  ;    
}

export const registerProfile = (state) => {
  const payload = packPayload(state, true)
  return apiClient.post('/register', payload)
} 

export const processValidationErrors = (data, setError) => {
  Object.entries(unpackPayload(data)).forEach(([field, errors]) => {
    setError(
      field,
      {
        type: "server",
        message: errors.join(' '),
      },
      {shouldFocus: true}
    );
  }) 
}

export const login = (state) => {
  const payload = packPayload(state, true)
  return apiClient.post('/auth/link', payload)
}

export const presentEmailLogin = (token) => {
  return apiClient.post('/auth/login', {passkey: token})
}

export const verifyEmail = (token) => {
  return apiClient.post('/verify/email', {passkey: token})
}

export const getProfile = () => {
  return apiClient.get('/profile')
}

export const updateProfile = (state) => {
  const payload = packPayload(state, true)
  return apiClient.post('/profile', payload)
}

export const getReferralSources = () => {
  return apiClient.get('/referral_sources')
}
