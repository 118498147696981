import { configureStore } from '@reduxjs/toolkit'
import profileSlice from './profileSlice'

// https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-store

export const store = configureStore({
  reducer: {
    profile: profileSlice,
  },
})
