import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema, fieldRequired } from '../state/profileSchema';
import { initialState } from '../state/profileSlice';
import { Input } from "../components/Input";
import { Select } from "../components/Select";
import { TextArea } from "../components/TextArea";
import styles from '../styles';
import { registerProfile, updateProfile, processValidationErrors } from '../state/apiClient';
import { boolToNum, formatPhoneOnChange } from "../util/forms";

// https://react-hook-form.com/get-started#Integratingwithglobalstate

export default function ProfileForm({ onSuccess, onFailure, defaultValues = {}, action = 'UPDATE', referralSources = null }) {

  const [ submitting, setSubmitting ] = useState(false)

  // get state from store
  // const profile = useSelector(state => state.profile);
  // ...and drop state into form as default values
  const formMethods = useForm({
    // for each boolean field rendered in a <select>, cast to num so form repopulates from DB correctly
    defaultValues: boolToNum({...initialState, ...defaultValues}, ['willBgCheck', 'hasReferences']),
    resolver: yupResolver(schema)
  });
  // const dispatch = useDispatch();

  // console.log('formMethods', formMethods)

  const onSubmit = async function (data) {
    const actionFcn = {
      'REGISTER': registerProfile,
      'UPDATE':   updateProfile,
    }

    setSubmitting(true);
    
    try {
      let response = await actionFcn[action](data)
      if(onSuccess) onSuccess(response)
    } catch (error) {
      if(error.response?.status === 400){
        processValidationErrors(error.response.data, formMethods.setError)
      } else {
        if(onFailure) onFailure(error);
      }
      console.log(action+' failure', error)
    }
    setSubmitting(false);
  }

  const opportunityOptions = [
    ['Full-time and part-time', 'ALL'],
    ['Full-time only', 'FT'],
    ['Part-time  only', 'PT'],
  ]

  const experienceOptions = [
    ['No Experience', 'NONE'],
    ['Some Experience', 'SOME'],
    ['Skilled Professional', 'SKILLED'],
    ['Experienced Leader', 'LEADER'],
  ]

  const transportationOptions = [
    ['Yes', 'YES'],
    ['Sometimes', 'SOMETIMES'],
    ['No', 'NO'],
  ]

  const bgCheckOptions = [
    ["Yes - I am", "1"],
    ["No - I'm not", "0"],
  ]

  const refOptions = [
    ["Yes - I can", "1"],
    ["No - I can't", "0"],
  ]

  const buttonText = {
    REGISTER: 'Sign Up',
    UPDATE: 'Save Profile'
  }

  const rowSingle = 'mb-4 md:mb-5';
  const rowFlex = 'flex flex-row gap-x-2';

  return (
    <FormProvider {...formMethods}>

      <form onSubmit={formMethods.handleSubmit(onSubmit)} noValidate>

        <div className={`${rowFlex}`}>
          <Input label="First Name" type="text" id="firstName" autoComplete="given-name" required={fieldRequired['firstName']} className={`${rowSingle} w-full md:w-1/2`} />
          <Input label="Last Name" type="text" id="lastName" autoComplete="family-name" required={fieldRequired['lastName']} className={`${rowSingle} w-full md:w-1/2`} />
        </div>

        <Input label="Email" type="email" id="email" autoComplete="email" required={fieldRequired['email']} className={rowSingle} />

        <Input label="Phone" inputMode='numeric' onChange={formatPhoneOnChange} type="text" id="phoneMobile" autoComplete="tel-national" required={fieldRequired['phoneMobile']} className={rowSingle} />

        <Input
          label="ZIP Code" type="text" id="postalCode"
          helperText="We'll use this to show you the opportunities closest to you."
          className={rowSingle}
          required={fieldRequired['postalCode']}
          autoComplete="postal-code"
          inputMode="numeric"
        />

        <Select
          label="Are you currently interested in full-time or part-time opportunities?"
          defaultOption="Select opportunity types..."
          id="opportunities" options={opportunityOptions}
          className={rowSingle}
          required={fieldRequired['opportunities']}
        />

        <Select
          label="How much cannabis industry experience do you have?"
          helperText="Don't worry if you're just starting out in the industry: there are opportunities available at all experience levels."
          defaultOption="Select experience level..."
          id="experience" options={experienceOptions}
          className={rowSingle}
          required={fieldRequired['experience']}
        />

        <Select
          label="Car transportation?"
          defaultOption="Select..."
          id="transportation" options={transportationOptions}
          className={rowSingle}
          required={fieldRequired['transportation']}
        />

        <Select
          label="Are you willing to undergo pre-employement background checks?"
          defaultOption="Select..."
          id="willBgCheck" options={bgCheckOptions}
          className={rowSingle}
          required={fieldRequired['willBgCheck']}
        />

        <Select
          label="Can you provide local references?"
          defaultOption="Select..."
          id="hasReferences" options={refOptions}
          className={rowSingle}
          required={fieldRequired['hasReferences']}
        />

        <Input
          label="LinkedIn Profile" type="text" id="resumeUrl"
          helperText="Do you have a LinkedIn profile or other online resume? If so, include the link to it here."
          className={rowSingle}
          required={fieldRequired['resumeUrl']}
        />
        <Input
          label="Social Media Profile" type="text" id="socialUrl"
          helperText="If you post photos of your cannabis experience on social media, include a link to your profile here for prospective employers."
          className={rowSingle}
          required={fieldRequired['socialUrl']}
        />

        <TextArea
          label="Share a little bit about yourself!" id="bio" maxLength="1000"
          className={rowSingle}
          required={fieldRequired['bio']}
        />

        {referralSources && (
          <Select
            label="How did you first learn about CannaWorkforce Connector?"
            defaultOption="Select..."
            id="source" options={referralSources}
            className={rowSingle}
          />

        )}

        <button type="submit" className={styles.buttonPrimary} disabled={submitting}>{submitting ? 'Saving...' : buttonText[action]}</button>
      
      </form>

    </FormProvider>
  )
}
