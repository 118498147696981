import React, { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { presentEmailLogin } from "../state/apiClient";
import { useAuth } from "../hooks/useAuth";


export default function EmailLogin() {

  const navigate = useNavigate();

  const { token } = useParams()

  const { login } = useAuth();

  useEffect(() => {
    async function asyncEffect() {
      try {
        const response =  await presentEmailLogin(token)
        login(response.data.token)
        navigate('/profile')
        toast.success('You have successfully logged in.')
      } catch (error) {
        navigate('/login')
        toast.error('Login attempt failed. Please try again.')
      }
    }
    asyncEffect();
  }, []);


  return (
    <div className="m-2 italic">Loading...</div>
  )
}
