export const boolToNum = (obj, keys=null) => {
  (keys ? keys : Object.keys(obj)).forEach(k => {
    if(typeof obj[k] === 'boolean'){
      obj[k] = obj[k] ? 1 : 0
    }
  })
  return obj
}

// generate options for react-hook-form's register fcn
// useful for integrating custom onChange and OnBlur functions
export const getRegisterOpts = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const formatPhone = (val) => {
  const digits = val.trim().replaceAll(/[^\d]/g, '').replace(/^1/, '')
  if(digits.length < 4) return digits;
  if(digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`
  if(digits.length < 11) return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)} x${digits.slice(10)}`
}

export const formatPhoneOnChange = (event) => {
  event.target.value = formatPhone(event.target.value)
}
