import { createSlice } from '@reduxjs/toolkit'
// import _ from "lodash"
// import apiClient from './apiClient'
// import { setSession } from './session';

export const initialState = {
  firstName: null,
  lastName: null,
  email: null,
  phoneMobile: null,
  postalCode: null,
  isAdult: null,
  opportunities: null,
  experience: null,
  hasReferences: null,
  willBgCheck: null,
  transportation: null,
  resumeUrl: null,
  socialUrl: null,
  bio: null,
}

// https://redux-toolkit.js.org/api/createAsyncThunk
// export const registerProfile = createAsyncThunk(
//   'profile/registerProfile',
//   async (ignoredArg, {getState}) => {
//     const state = getState().profile
    
//     const payload = _
//       .chain(state)
//       .toPairs()
//       .filter(([k, v]) => v !== null)
//       .map(([k, v]) => [_.snakeCase(k), v])
//       .fromPairs()
//       .value()
//     ;

//     return await apiClient.post('/register', payload)
//   }  
// )

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileUpdated(state, action){
      console.log('reducer', state, action.payload)
      Object.entries(action.payload).forEach(([k, v]) => {
        if(! (k in state)){
          console.error(`no key ${k} in profile state`)
          return;
        }
        // state[k] = v
      });
      return {...state, ...action.payload}
    },
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(registerProfile.fulfilled, (state, action) => {
  //       console.log('registerProfile.fulfilled', action.payload)
  //       // state.remoteId = action.payload.id
  //       setSession(action.payload.data.token)
  //     })
  //     .addCase(registerProfile.rejected, (state, action) => {
  //       console.log('registerProfile.rejected', action)
  //     })
  // }
})

// Action creators are generated for each case reducer function
export const { profileUpdated } = profileSlice.actions

export default profileSlice.reducer
