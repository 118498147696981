export const HelperText = ({ message, hasError }) => {

  const stdClasses = 'text-paris-white-700'
  const errorClasses = 'text-stiletto-700'

  return (
    <>
      { message && (<div className={'italic mb-1 mt-1 text-sm ' + (hasError ? errorClasses : stdClasses)}>{message}</div>)}
    </>
  )

}
