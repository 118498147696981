import { createContext, useContext, useMemo, useState } from "react";
import { getSessionToken, setSessionToken, destroySessionToken } from "../state/sessionToken";
import { useNavigate, useOutlet } from "react-router-dom";
import { toast } from "react-hot-toast";

const AuthContext = createContext();

// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/#creating-protected-routes
export const AuthProvider = ({ children }) => {

  const defaultSessionToken = false
  
  const navigate = useNavigate();
  
  const [sessionId, setSessionId] = useState(() => {
    try {
      return getSessionToken()
    } catch (err) {
      return null;
    }
  });

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setSessionToken(data)
    setSessionId(data)
  };

  // call this function to sign out logged in user
  const logout = (defaultToast=true) => {
    destroySessionToken()
    setSessionId(defaultSessionToken)
    navigate("/", { replace: true });
    if(defaultToast) toast('You have been successfully logged out.')
  };

  const getSession = () => {
    return sessionId
  }

  const value = useMemo(
    () => ({
      session: sessionId,
      login,
      logout,
      getSession
    }),
    [sessionId]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const  AuthLayout = () => {
  const outlet = useOutlet();

  return (
    <AuthProvider>{outlet}</AuthProvider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};