import { useRouteError } from "react-router-dom";
import styles from '../styles';
import { Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <section id="error-page" className={styles.section}>
      <div className={styles.sectionContainer}>
        <h2 className={styles.h2}>Oops</h2>
        <p className={styles.p}>Sorry, an unexpected error has occurred.</p>
        <p className={styles.p}>
          <i>{error.statusText || error.message}</i>
        </p>
        <p className={styles.p}><Link to="/" className="text-fiery-orange-600 underline">Return to home page</Link></p>
      </div>
    </section>
  );
}
