import React, { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { verifyEmail } from "../state/apiClient";
import { useAuth } from "../hooks/useAuth";


export default function EmailVerify() {

  const navigate = useNavigate();

  const { token } = useParams()

  const { session } = useAuth();

  useEffect(() => {
    async function asyncEffect() {
      try {
        await verifyEmail(token)
        toast.success('You have verified your email adddress')
      } catch (error) {
        toast.error('Error verifying your email. Please try again.')
      }
      navigate(session ? '/profile' : '/')
    }
    asyncEffect();
  }, []);


  return (
    <div className="m-2 italic">Loading...</div>
  )
}
