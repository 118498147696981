import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from '../styles';

export default function PageStandard({ showHeader=true, showFooter=true, children }) {

  return (
    <>
      {showHeader && (<Header />)}
      <section className={styles.section}>
        <div className={styles.sectionContainer}>
          {children}
        </div>
      </section>
      {showFooter && (<Footer />)}
    </>
  )
}
