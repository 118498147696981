const sessionKey = 'session';

export const getSessionToken = () => {
  return window.localStorage.getItem(sessionKey);
}

export const setSessionToken = (val) => {
  window.localStorage.setItem(sessionKey, val)
}

export const destroySessionToken = () => {
  window.localStorage.removeItem(sessionKey)
}
