import { useEffect } from 'react';
import { useAuth } from "../hooks/useAuth";


export default function Logout() {

  const { logout } = useAuth();

  useEffect(() => {
    async function asyncEffect() {
      logout()
    }
    asyncEffect();
  }, []);


  return (
    <div className="m-2 italic">Loading...</div>
  )
}
