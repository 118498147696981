import { useFormContext } from "react-hook-form"
import { Label } from "./Label";
import { HelperText } from "./HelperText";
import { getRegisterOpts } from "../util/forms"

export const Input = (props) => {

  const { label, id, helperText, className, required, onChange=null, onBlur=null, ...inputProps } = props

  const {
    register,
    formState: { errors },
  } = useFormContext()

  const registerOpts = getRegisterOpts({onChange, onBlur})

  const message = (id in errors ? errors[id].message : helperText)

  return (
    <div className={className}>
      <Label label={label} id={id} hasError={id in errors} required={required} />
      <input
        id={id}
        className="w-full h-10 bg-white rounded border border-gray-300 focus:border-sage-500 focus:ring-3 focus:ring-sage-200 text-base outline-none text-feldgrau-900 py-2 px-3 transition-colors duration-200 ease-in-out"
        {...register(id, registerOpts)}
        {...inputProps}
      />
      <HelperText message={message} hasError={id in errors} />
    </div>
  )

}
