import { useFormContext } from "react-hook-form"
import { Label } from "./Label";
import { HelperText } from "./HelperText";
import { styler } from "../styles";

export const TextArea = ({ label, id, placeholder, helperText, maxLength, className, required }) => {

  const {
    register,
    formState: { errors },
  } = useFormContext()

  const message = (id in errors ? errors[id].message : helperText)

  return (
    <div className={className}>
      <Label label={label} id={id} hasError={id in errors} required={required} />
      <textarea
        id={id}
        maxLength={maxLength}
        className={styler.textArea()}
        placeholder={placeholder}
        {...register(id)}
      ></textarea>
      <HelperText message={message} hasError={id in errors} />
    </div>
  )

}
