import PageStandard from "../components/PageStandard";
import styles from '../styles';
import { useSearchParams } from "react-router-dom";

export default function Join() {

  const [searchParams] = useSearchParams();

  const isIframeMode = (searchParams.get('mode') === 'iframe')

  return (
    <PageStandard showHeader={!isIframeMode} showFooter={!isIframeMode}>

      <h2 className={styles.h2}>Thanks for joining!</h2>

      <p className={styles.p + ' text-lg'}>We'll send you an email when there's an opportunity that's a good fit for you and when we've added new networking features for you here.</p>

      <p className={styles.p + ' text-lg'}>Please check your inbox for our verification email and click on the link in it.</p>

    </PageStandard>
  )
}
