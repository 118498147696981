import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from 'yup';
import { login, processValidationErrors } from "../state/apiClient";
import { HelperText } from './HelperText';
import { Link, useNavigate } from "react-router-dom"
import toast from 'react-hot-toast';
import { useState } from "react";

export default function LoginForm() {

  const [ submitting, setSubmitting ] = useState(false)
  const [ success, setSuccess ] = useState(false)

  const schema = object({
    email: string().trim().email('Invalid email address').required('Required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
} = useForm({
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()

  const onSubmit = (data) => {
    setSubmitting(true);
    login(data).then(() => {
      setSuccess(true)
    }).catch((error) => {
      if(error.response.status === 404){
        processValidationErrors(error.response.data, setError)
      } else {
        toast.error('An error occurred while processing your login. Please try again.')
      }
    }).finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {!success && (
        <>
          <div className="mb-4">
            <label htmlFor="email" className="font-label leading-7 text-lg font-semibold text-fiery-orange-600 mb-2">Email</label>
            <input type="email" autoComplete="email" id="email" name="email" {...register("email")} className="w-full bg-white rounded border border-gray-300 focus:border-sage-500 focus:ring-3 focus:ring-sage-200 text-base outline-none text-feldgrau-900 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            {'email' in errors && <HelperText message={errors.email.message} hasError={true} />}
          </div>
          <button type="submit" disabled={submitting} className="font-button text-white bg-sage-500 border-0 py-2 px-8 focus:outline-none hover:bg-sage-600 rounded text-lg w-full">{submitting ? 'Logging in...' : 'Log in'}</button>
          <div className="my-4 flex">
            <div className="flex-auto bg-holly-600 h-px mt-3"></div>
            <div className="flex-none font-title font-semibold px-4">or</div>
            <div className="flex-auto bg-holly-600 h-px mt-3"></div>
          </div>
          <Link
            to="/join"
            onClick={(e) => {
              e.preventDefault()
              navigate('/join', {state: {email: getValues('email')}})
            }}
            className="font-button font-semibold bg-transparent border-2 border-sage-700 py-2 px-8 focus:outline-none hover:bg-rice-cake-300 rounded text-lg text-center w-full block"
          >Join now</Link>
        </>
      )}
      {success && (
        <div className="text-lg">
          <p className="font-medium mb-4">Please check your email</p>
          <p>We've sent you a special link for a password-free log in.</p>
        </div>
      )}
    </form>
  )
}