import { addMethod, object, string, boolean } from 'yup';

const requiredStr = 'Required'
const urlStr = 'Must be a URL starting with http:// or https://'

// https://stackoverflow.com/questions/68136485/yup-validation-convert-empty-string-to-default-value

const stripEmpty = function () {
  return this.transform((value) => (value === '' ? undefined : value));
}

addMethod(string, 'stripEmpty', stripEmpty);
addMethod(boolean, 'stripEmpty', stripEmpty);

const validatePhone = {
  name: 'validate-phone',
  test: (value) => {
    let digits = value.replaceAll(/[^\d]/g, '')
    if(digits[0] === '1'){
      digits = digits.slice(1)
    }
    return digits.length >= 10;
  },
  message: 'Must be a valid phone number'
}

const schema = object({
  firstName: string().required(requiredStr).trim().min(1, 'Cannot be empty'),
  lastName: string().required(requiredStr).trim().min(1, 'Cannot be empty'),
  email: string().required(requiredStr).trim().email('Must be an email address'),
  phoneMobile: string().required(requiredStr).trim().test(validatePhone),
  postalCode: string().required(requiredStr).trim().min(5, 'Must be a ZIP code'),
  isAdult: boolean(),
  opportunities: string().stripEmpty().nullable(),
  experience: string().stripEmpty().nullable(),
  hasReferences: boolean().stripEmpty().nullable(),
  willBgCheck: boolean().stripEmpty().nullable(),
  transportation: string().stripEmpty().nullable(),
  resumeUrl: string().url(urlStr).stripEmpty().nullable(),
  socialUrl: string().url(urlStr).stripEmpty().nullable(),
  bio: string().stripEmpty().nullable().max(1000, '1000 characters max'),
  source: string().stripEmpty().nullable(),
})

const fieldRequired = Object.fromEntries(
  Object.entries(schema.describe().fields).map(([k, data]) => {
    return [k, data.tests.some(t => t.name === 'required')]
  })
)

export { schema, fieldRequired }