import PageStandard from "../components/PageStandard";
import ProfileForm from '../components/ProfileForm';
import { getProfile, unpackPayload } from '../state/apiClient';
import styles from '../styles';
import { useLoaderData } from 'react-router-dom'
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";


export async function loader() {
  try {
    const { data } = await getProfile()
    if('id' in data) {
      delete data['id']
    }
    return { data: unpackPayload(data) }
  } catch (error) {
    if(error.response.status === 401) return {data: null};
    throw new Response(error.response.statusText, { status: error.response.status });
  }
}

// https://react-hook-form.com/get-started#Integratingwithglobalstate

export default function Profile() {

  const { data } = useLoaderData();

  const { logout } = useAuth()

  useEffect(
    () => {
      if(data === null){
        logout(false)
        toast.error('Your session has expired. Please log in again.')
      }
    },
    // eslint-disable-next-line
    [data]
  )

  const onSuccess = () => {
    toast.success('Profile saved.')
  }

  const onFailure = () => {
    toast.error('Profile could not be updated.')
  }

  return (
    <PageStandard>

      <h2 className={styles.h2}>Profile</h2>

      <ProfileForm onSuccess={onSuccess} onFailure={onFailure} defaultValues={data} />
    
    </PageStandard>
  )
}
