import LoginForm from "../components/LoginForm";
import HeroPic from "../assets/hphero.01.webp";

export default function Root() {
  return (
    <>
      <section
        style={{'--image-url': `url(${HeroPic})`}} 
        className='bg-[image:var(--image-url)] min-h-screen bg-no-repeat bg-cover bg-center bg-fixed'
      >
        <div className="w-screen min-h-screen bg-white-70">

          <div className="container mx-auto px-5 pt-5 lg:pt-10 pb-5 lg:pb-10">
            <h1 className="font-title font-semibold text-3xl md:text-4xl text-sage-600">CannaWorkforce Connector</h1>
          </div>

          <div className="container px-5  mx-auto flex flex-wrap items-start">
            
            <div className="lg:w-3/5 md:pr-16 lg:pr-0 pr-0">
              <h1 className="font-title text-4xl lg:text-5xl text-fiery-orange-700">Get Connected to New York's Cannabis Industry</h1>
              
              <p className="leading-relaxed mt-8 text-2xl lg:text-3xl font-normal text-paris-white-800">Find job opportunities and build your professional network with CannaWorkforce Connector</p>
            </div>
            <div
              style={{'--tw-bg-opacity': '0.8'}}
              className="lg:w-2/6 bg-rice-cake-200 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-5"
            >
              <LoginForm />
            </div>
          </div>

          <div className="container mx-auto px-5 text-sm text-paris-white-400 mt-20 md:mt-52 ">
            <span className="font-sans">&copy;</span> 2023 Trimmr LLC
          </div>

        </div>
      </section>
    </>
  )
}