import { useAuth } from "../hooks/useAuth"
import { useNavigate, Link } from 'react-router-dom'
import { Bars3Icon } from '@heroicons/react/24/solid'
import { useState } from "react";

export default function Header() {

  const navigate = useNavigate();

  const { session, logout } = useAuth();
  const [ menuActive, setMenuActive ] = useState(false)

  const onClick = () => {
    if(session) {
      logout()
    } else {
      navigate('/login')
    }
  }

  return (
    <header className="bg-sage-200">
      <nav className="container mx-auto flex flex-wrap p-5 items-center justify-between">
        <div>
          <Link className="font-title font-semibold text-feldgrau-800 mb-4 md:mb-0" to="/">
            <span className="text-xl">CWFC</span>
          </Link>
        </div>

        <div className="h-8 w-8">
          <Bars3Icon
            className="cursor-pointer md:hidden text-fiery-orange-950"
            onClick={() => setMenuActive(!menuActive)}
          />
        </div>

        <div className={`w-full md:flex md:items-center md:w-auto` + (menuActive ? '' : ' hidden')}>
          <ul
            className="
              pt-4
              text-base
              md:flex
              md:justify-between
              md:pt-0
              text-right"
          >
            <li>
              <button onClick={onClick} className="font-button inline-flex items-center bg-fiery-orange-400 border-0 py-1 px-3 focus:outline-none hover:bg-fiery-orange-500 rounded text-base mt-4 md:mt-0">
                {session ? 'Log Out' : 'Log In'}
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}
