import PageStandard from "../components/PageStandard";
import styles from '../styles';
import LoginForm from "../components/LoginForm";

export default function Login() {

  return (
    <PageStandard>

      <div className="mx-auto w-full md:w-1/3">
        <h2 className={styles.h2}>Log In</h2>
        <LoginForm />
      </div>

    </PageStandard>
  )
}
