import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import Root from './routes/root';
import Join, { loader as joinLoader } from './routes/join';
import Welcome from './routes/welcome';
import Login from './routes/login';
import Logout from './routes/logout';
import EmailLogin from './routes/emailLink';
import EmailVerify from './routes/emailVerify';
import Profile, { loader as profileLoader } from './routes/profile';
import ErrorPage from "./routes/error-page";
import { store } from './state/store'
import { Provider } from 'react-redux'
import { ProtectedRoute, PublicOnlyRoute } from './components/PermissionedRoutes';
import { Toaster } from 'react-hot-toast';
import { AuthLayout } from './hooks/useAuth';
import * as Sentry from "@sentry/react";
// import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://3b966d04597a4f2e8f07f7a455358f12@o4505434792591360.ingest.sentry.io/4505434794819584",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/workforce\.trimmr\.com\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_RUNLEVEL,
  debug: (process.env.REACT_APP_RUNLEVEL === 'dev'),
});

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Root />,
      },
      {
        path: "/join",
        loader: joinLoader,
        element: <Join />,
      },
      {
        path: '/welcome',
        element: <ProtectedRoute>
          <Welcome />
        </ProtectedRoute>,
      },
      {
        path: '/login',
        element: <PublicOnlyRoute>
          <Login />
        </PublicOnlyRoute>,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/email/link/:token',
        element: <EmailLogin />,
      },
      {
        path: '/email/verify/:token',
        element: <EmailVerify />,
      },
      {
        path: '/profile',
        loader: profileLoader,
        element: <ProtectedRoute>
          <Profile />
        </ProtectedRoute>
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
      <RouterProvider router={router} />
      <Toaster />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
